import * as React from "react";
import {useEffect} from "react";
import {MenuItem} from "@material-ui/core";
import {useStyles} from "./activeCargos.styles";
import {CardActive} from "../../../../../../components/cardActive/cardActive.component";
import {Container} from "../../../../../../components/container/container.component";
import {Form} from "react-final-form";
import {FormFieldWrapper} from "../../../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Select} from "../../../../../../components/select/select";
import {IActiveCargosFormValues} from "../../../../interfaces/forms/activeCargos";
import {
	getUniqueRegistrationNumbers,
	getCardsByRegistrationNumber,
	getInitialRegistrationNumber,
	isCustomRegistrationNumberEnabled, getRegistrationNumberFromCookie
} from "../../../../../../helpers/cards";
import {Message} from "../../../../../../components/message";
import Cookies from "js-cookie";

export function ActiveCargosComponent(props) {
	const {
		fetchConfirmationGeneratedCards,
		confirmationGeneratedCards,
		printConfirmation,
		clearRefreshCurrentPage, refreshCurrentPageStatus,
		apiType,
	} = props;
	const classes = useStyles({});

	useEffect(() => {
		fetchConfirmationGeneratedCards(apiType);
	}, [apiType]);

	useEffect(() => {
		if (refreshCurrentPageStatus !== null) {
			clearRefreshCurrentPage();
			fetchConfirmationGeneratedCards(apiType);
		}
	}, [refreshCurrentPageStatus]);

	function getApprovedCardsSection(vehicleRegNumber) {
		const cards = getCardsByRegistrationNumber(confirmationGeneratedCards, vehicleRegNumber);

		return (
			<>
				<div>
					{getApprovedCardsTable(cards)}
				</div>
			</>
		);
	}

	function getApprovedCardsTable(cards) {
		if (!cards) {
			return (<Message>Ładowanie wyników...</Message>);
		} else if (!cards.length) {
			return (
				<>
					<Message>Brak wyników.</Message>
				</>
			);
		} else {
			return cards.map((card, index) => (
				<CardActive
					key={card.kpoId || card.kpokId}
					card={card}
					onPrintConfirmationClick={handlePrintConfirmation}
				/>
			));
		}
	}

	function handlePrintConfirmation(card) {
		printConfirmation(apiType, card);
	}

	const onSubmit = async (values: IActiveCargosFormValues) => {

	};

	const handleVehicleNumberChange = (value) => {
		if (!isCustomRegistrationNumberEnabled()) {
			Cookies.set("mbdo_vehicleRegNumber", value);
		}
	};

	const allCards = confirmationGeneratedCards || [];
	const uniqueRegistrationNumbers = getUniqueRegistrationNumbers(allCards);
	const initialRegistrationNumber = getInitialRegistrationNumber(
		getRegistrationNumberFromCookie(),
		uniqueRegistrationNumbers,
	);

	const initialValues: IActiveCargosFormValues = {
		vehicleRegNumber: initialRegistrationNumber,
	};

	return (
		<>
			<Container classNames={{root: classes.root}}>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values , }) => {
						return (
							<>
								<form onSubmit={handleSubmit}>
									{allCards.length > 0 && <FormFieldWrapper>
										<Select
											required={true}
											name="vehicleRegNumber"
											label="Filtruj wg. numeru rejestracyjnego"
											onChange={handleVehicleNumberChange}
										>
											{uniqueRegistrationNumbers.map((regNumber) => {
												return (<MenuItem key={regNumber} value={regNumber}>{regNumber}</MenuItem>);
											})}
										</Select>
									</FormFieldWrapper>}

									{getApprovedCardsSection(values.vehicleRegNumber)}
								</form>
							</>
						);
					}}
				/>
			</Container>
		</>
	);
}
