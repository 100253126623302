import * as React from "react";
import {useEffect} from "react";
import {MenuItem} from "@material-ui/core";
import {useStyles} from "./cargosToTerminate.styles";
import {CardReceiveConfirmation} from "../../../../../../components/cardReceiveConfirmation/cardReceiveConfirmation.component";
import {EndTransportDialog} from "../../../../containers/dialogs/endTransport/endTransportDialog";
import {Container} from "../../../../../../components/container/container.component";
import {Form} from "react-final-form";
import {FormFieldWrapper} from "../../../../../../components/formFieldWrapper/formFieldWrapper.component";
import {Select} from "../../../../../../components/select/select";
import {IActiveCargosFormValues} from "../../../../interfaces/forms/activeCargos";
import {
	getUniqueRegistrationNumbers,
	getCardsByRegistrationNumber,
	getInitialRegistrationNumber,
	isCustomRegistrationNumberEnabled, getRegistrationNumberFromCookie
} from "../../../../../../helpers/cards";
import {Message} from "../../../../../../components/message";
import Cookies from "js-cookie";
import {ApiTypes} from "../../../../../../constants/apiTypes";
import {Locations} from "../../../../../../constants/locations";
import {useHistory} from "react-router-dom";

export function CargosToTerminateComponent(props) {
	const {
		receiveConfirmationCards,
		finishTransport,
		fetchReceiveConfirmationCards,
		clearRefreshCurrentPage, refreshCurrentPageStatus,
		apiType, updateFormData,
	} = props;
	const classes = useStyles({});
	const [state, setState] = React.useState({
		opened: false,
		card: null,
	});
	let history = useHistory();

	useEffect(() => {
		fetchReceiveConfirmationCards(apiType);
	}, [apiType]);

	useEffect(() => {
		if (refreshCurrentPageStatus !== null) {
			clearRefreshCurrentPage();
			fetchReceiveConfirmationCards(apiType);
		}
	}, [refreshCurrentPageStatus]);

	function getReceiveConfirmationCardsSection(vehicleRegNumber) {
		const cards = getCardsByRegistrationNumber(receiveConfirmationCards, vehicleRegNumber);

		return (
			<>
				<div>
					{getReceiveConfirmationCardsTable(cards)}
				</div>
			</>
		);
	}

	function getReceiveConfirmationCardsTable(cards) {
		if (!cards) {
			return (<Message>Ładowanie wyników...</Message>);
		} else if (!cards.length) {
			return (<Message>Brak wyników</Message>);
		} else {
			return cards.map((card, index) => (
				<CardReceiveConfirmation
					key={card.kpoId || card.kpokId}
					card={card}
					onEndCargoClick={handleEndCargo} />
			));
		}
	}

	function handleEndCargo(card) {
		if (apiType === ApiTypes.KPOK) {
			updateFormData({
				from: Locations.ACTIVE_CARGOS,
				type: "endTransport",
				card: card,
			});

			history.push(Locations.END_TRANSPORT);
		} else {
			setState((prevState) => {
				return {
					opened: true,
					card,
				};
			});
		}
	}

	function handleEndCargoModalConfirm() {
		if (state.card) {
			finishTransport(apiType, state.card.kpoId || state.card.kpokId);
		}

		setState((prevState) => {
			return {
				opened: false,
				card: null,
			};
		});
	}

	function handleEndCargoModalCancel() {
		setState((prevState) => {
			return {
				opened: false,
				card: null,
			};
		});
	}

	function handleEndCargoModalClose() {
		setState((prevState) => {
			return {
				opened: false,
				card: null,
			};
		});
	}

	const onSubmit = async (values: IActiveCargosFormValues) => {

	};

	const handleVehicleNumberChange = (value) => {
		if (!isCustomRegistrationNumberEnabled()) {
			Cookies.set("mbdo_vehicleRegNumber", value);
		}
	};

	const allCards = receiveConfirmationCards || [];
	const uniqueRegistrationNumbers = getUniqueRegistrationNumbers(allCards);
	const initialRegistrationNumber = getInitialRegistrationNumber(
		getRegistrationNumberFromCookie(),
		uniqueRegistrationNumbers,
	);

	const initialValues: IActiveCargosFormValues = {
		vehicleRegNumber: initialRegistrationNumber,
	};

	return (
		<>
			<EndTransportDialog open={state.opened} onConfirm={handleEndCargoModalConfirm} onCancel={handleEndCargoModalCancel} onClose={handleEndCargoModalClose} />
			<Container classNames={{root: classes.root}}>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values , }) => {
						return (
							<>
								<form onSubmit={handleSubmit}>
									{allCards.length > 0 && <FormFieldWrapper>
										<Select
											required={true}
											name="vehicleRegNumber"
											label="Filtruj wg. numeru rejestracyjnego"
											onChange={handleVehicleNumberChange}
										>
											{uniqueRegistrationNumbers.map((regNumber) => {
												return (<MenuItem key={regNumber} value={regNumber}>{regNumber}</MenuItem>);
											})}
										</Select>
									</FormFieldWrapper>}

									{getReceiveConfirmationCardsSection(values.vehicleRegNumber)}
								</form>
							</>
						);
					}}
				/>
			</Container>
		</>
	);
}
