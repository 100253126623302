import React, {useEffect} from "react";
import {get as lodashGet} from "lodash";
import {Form} from "react-final-form";
import {Button} from "../../../../components/button/button";
import {
	MenuItem,
} from "@material-ui/core";
import {ISelectCargoFormValues} from "../../interfaces/forms/selectCargo";
// import {useStyles} from "./selectCargo.styles";
import {Select} from "../../../../components/select/select";
import {useHistory} from "react-router-dom";
import {CardDetailsSelectCargo} from "../../../../components/cardDetailsSelectCargo/cardDetailsSelectCargo.component";
import {CardWrapper} from "../../../../components/cardWrapper/cardWrapper.component";
import {Locations} from "../../../../constants/locations";
import {Header} from "../../../../components/header/header.component";
import {Container} from "../../../../components/container/container.component";
import {Message} from "../../../../components/message";
import {FormFieldWrapper} from "../../../../components/formFieldWrapper/formFieldWrapper.component";
import {
	getUniqueRegistrationNumbers,
	getCardsByRegistrationNumber,
	getCardById,
	getInitialRegistrationNumber, // isCustomRegistrationNumberEnabled, getRegistrationNumberFromCookie
} from "../../../../helpers/cards";
import Cookies from "js-cookie";
import {CardMenuItemContent} from "../../../../components/cardMenuItemContent/cardMenuItemContent.component";
//selectCardOnSelectCargoScreen
export function SelectCargoComponent(props) {
	const {
		approvedCards, fetchApprovedCards, updateFormData, exchangedData, apiType,
		getCardDetails, isFetchingCardDetails, selectedCardDetails, /*selectedCard, selectCardOnSelectCargoScreen*/
	} = props;
	//const classes = useStyles({});
	const [selectedCard, setSelectedCard] = React.useState(null);

	let history = useHistory();

	useEffect(() => {
		fetchApprovedCards(apiType);
	}, []);

	useEffect(() => {
		if (selectedCard) {
			getCardDetails(apiType, selectedCard.kpoId || selectedCard.kpokId);
		}
	}, [selectedCard]);

	const onSubmit = async (values: ISelectCargoFormValues) => {
		const selectedCard = getCardById(approvedCards, values.cardId);

		updateFormData({
			from: Locations.SELECT_CARGO,
			formValues: values,
			selectedCard: selectedCard,
		});
		history.push(Locations.CARGO_CONFIRMATION);
	};

	const areCardDetailsLoading = () => {
		return isFetchingCardDetails[selectedCard.kpoId || selectedCard.kpokId] || false;
	};

	const handleVehicleNumberChange = (value) => {
		Cookies.set("mbdo_vehicleRegNumber", value);
	};

	const handleValidation = (values: ISelectCargoFormValues) => {
		const errors: any = {};

		if (!values.vehicleRegNumber) {
			errors.vehicleRegNumber = "To pole jest wymagane";
		}

		if (!values.cardId) {
			errors.cardId = "To pole jest wymagane";
		}

		return errors;
	};

	if (!approvedCards) {
		return (
			<Container>
				<Header>
					Wybór transportu
				</Header>
				<Message>
					Ładowanie wyników...
				</Message>
			</Container>
		);
	} else if (!approvedCards.length) {
		return (
			<Container>
				<Header>
					Wybór transportu
				</Header>
				<Message>
					Aktualnie nie ma żadnych transportów do wybrania.
				</Message>
			</Container>
		);
	} else {
		const uniqueRegistrationNumbers = getUniqueRegistrationNumbers(approvedCards);
		let initialRegistrationNumber = getInitialRegistrationNumber(
			Cookies.get("mbdo_vehicleRegNumber"),
			uniqueRegistrationNumbers,
		);

		let initialCardId = "";

		if (lodashGet(exchangedData, "type") === "goBack") {
			initialRegistrationNumber = lodashGet(exchangedData, "formValues.vehicleRegNumber");
			initialCardId = lodashGet(exchangedData, "formValues.cardId");
		}

		if (selectedCard) {
			initialCardId = selectedCard.kpoId || selectedCard.kpokId;
		}

		const initialValues: ISelectCargoFormValues = {
			vehicleRegNumber: initialRegistrationNumber,
			cardId: initialCardId,
		};

		return (
			<Container>
				<Header>
					Wybór transportu
				</Header>
				<Form
					validate={handleValidation}
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit, form, submitting, pristine, values , }) => {
						setSelectedCard(getCardById(approvedCards, values.cardId));

						const formHasErrors = Boolean(Object.keys(handleValidation(values)).length);

						return (
							<>
								<form onSubmit={handleSubmit} noValidate={true}>
									<FormFieldWrapper>
										<Select
											required={true}
											name="vehicleRegNumber"
											label="Nr. rejestracyjny pojazdu"
											onChange={(value) => {
												form.change("cardId", "");
												handleVehicleNumberChange(value);
											}}
										>
											{uniqueRegistrationNumbers.map((regNumber) => {
												return (<MenuItem key={regNumber} value={regNumber}>{regNumber}</MenuItem>);
											})}
										</Select>
									</FormFieldWrapper>
									<FormFieldWrapper>
										<Select
											required={true}
											name="cardId"
											label="Karta przekazania odpadów"
										>
											{getCardsByRegistrationNumber(approvedCards, values.vehicleRegNumber).map((card) => {
												return (
													<MenuItem key={card.kpoId || card.kpokId} value={card.kpoId || card.kpokId}>
														<CardMenuItemContent apiType={apiType} card={card} />
													</MenuItem>
												);
											})}
										</Select>
									</FormFieldWrapper>
									{
										selectedCard && !formHasErrors &&
											(<CardWrapper>
												<CardDetailsSelectCargo color="default" card={selectedCard} cardDetails={selectedCardDetails} />
												<FormFieldWrapper alignContent="center">
													<Button variant="contained" color="primary" type="submit" disabled={areCardDetailsLoading() || formHasErrors}>
														{areCardDetailsLoading() ? "Trwa ładowanie..." : "Wybierz"}
													</Button>
												</FormFieldWrapper>
											</CardWrapper>)
									}
								</form>
							</>
						);
					}}
				/>
			</Container>
		);
	}
}



